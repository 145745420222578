


.player-grid {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 20px 4fr auto;
    gap: 20px;
    height: auto;
    width: 80%;
    margin-bottom: 30px;
    align-items: center;
    background-color: white;
    border-radius: 20px;
  }
  
  .player-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    background-color: transparent;

    
    padding-top: 45px; /* 필요한 경우 패딩을 추가하여 위치를 조정할 수 있습니다. */
  }
  
  .player-image {
    width: 110px;
    height: 90px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 932px) {
    .player-grid {
      width: 100%; /* 가로 크기를 조금 더 넓게 설정 */
      margin-left: auto; /* 가로 가운데 정렬을 위한 수정 */
      margin-right: auto; /* 가로 가운데 정렬을 위한 수정 */
      grid-template-columns: 90px 1fr;
    }
    .player-section .player-image{
        width: 80px !important;
        height: auto;
        align-self: center !important;
      
    }

    .player-section {
      align-items: flex-start;
      padding-left: 2px; /* 필요에 따라 조정 */
    }
  
    .player-section .player-image,
    .player-section .player-name {
      align-self: flex-start;
    }

    .player-name {
      align-self: center; /* 이름을 가운데로 정렬 */
      width: 100%; /* 이름의 너비를 부모 요소의 너비로 설정 */
      text-align: center; /* 텍스트를 가운데로 정렬 */
    }

    .team-name{
      font-size: small;
    }

  }


  .player-name {
    text-align: center;
  }
  
  /* 선수들 맨 위에 나오는 헤더 이름 ex) 영국 프리미어리그 / Tottenham Hotspurs */
  .team-name {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;
    margin-top: 20px;
    font-size: small;
    
  }
  
  .match-info {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center;
    justify-self: left;
    background-color: transparent;
    max-width: 90%;
    margin-left: 5%;
    overflow: hidden;
  }
  
  .match-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  
  .match-details:hover {
    transform: scale(1.02);
  }
  
  .match-detail-panel {
    margin-top: 0px;
    position: relative;
    border-top: 2px solid #ccc;
    z-index: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    height: 240px;
    /* max-height: 300px; */
    opacity: 1;
    overflow: hidden;
    grid-column: 1 / 3;
    grid-row: 3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-template-rows: auto 1fr;
    
}

.match-detail-panel-closed {
  max-height: 0;
  
}

.match-detail-panel-open {
  max-height: 300px;
  
}

.detail-header {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 50px;
    padding: 2px;
    /* border-bottom: 1px solid #ccc; */
    

}

.panel-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0; /* 필요하다면 패딩을 조정하십시오 */
  
}

.panel-headers div {
  
  font-weight: 800;
  font-size: 1.1em;
  
  text-align: center;
  flex: 1; /* 이를 통해 각 헤더 div가 동일한 너비를 가짐 */
  margin-top: 20px;
  
}


.panels-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    
}

@media screen and (max-width: 1731) {
  .panels-container{  font-size:14px;  }
}
@media screen and (max-width: 1580) {
  .left-panel table{ width : 90%; }
  .panels-container{  font-size:12px;  }
}



.left-panel {
  flex: 1;
  padding: 10px;
  display: flex;  /* 추가된 코드 */
  justify-content: center;  /* 추가된 코드 */
  align-items: center;  /* 추가된 코드 */
  /* border: 1px solid #ccc;*/
  border-radius: 10px;
  margin-left: 10px;


}

.left-panel table {
  width: 60%;  /* 테이블의 너비를 패널의 너비로 설정 */
  margin: auto;
  border-collapse: collapse;  /* 테이블 셀 간격 제거 */
  
  
}

.left-panel td{

  width: 100px;
  padding: 1px;
  border-bottom: 1px solid #ddd;
  
}

/* 테이블 헤더 스타일 */
.left-panel th {
  font-weight: bold;  /* 텍스트 굵게 */
  border-bottom: 1px solid #ddd; 
  
}

.left-panel, .center-panel, .right-panel {
    flex: 1;
    padding: 10px;
    /*  border: 1px solid #ccc; */
    
    
}

.center-panel {
  flex: 1;
  padding: 8px;
  display: flex;  /* 추가된 코드 */
  justify-content: center;  /* 추가된 코드 */
  align-items: center;  /* 추가된 코드 */
  border-radius: 10px;
  
}

.center-panel table {
  width: 80%;  /* 필요에 따라 너비를 조정할 수 있습니다. */
  border-collapse: collapse;
  margin: auto;  /* 추가된 코드 */
  table-layout: fixed;  /* 테이블 레이아웃을 고정으로 설정 */
}

.center-panel th, .center-panel td {
  text-align: left;
  padding: 1px;
  border-bottom: 1px solid #ddd;
}

.center-panel th {
  font-weight: bold;
  
  text-align: right;
}
.center-panel td {
  width: 50%;  /* 원하는 너비 비율로 설정 */
  padding-left: 20px;
  
}



  .arrow-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
    position: absolute;
    right: 10px;
    top: 12%;
    transform: translateY(-50%);
  }
  
  .arrow-icon:hover {
    transform: scale(1.3) translateY(-50%);
  }
  
  .team-logo {
    width: 50px;
    height: 50px;
    
  }
  
  /* 중앙 슬라이드 */
  .swiper-slide-active {
    transform: scale(1.03) !important;
    margin-top: 5px;
    
  }

  .swiper-slide-active .match-date, 
.swiper-slide-active .match-time {
  color: orange;
}

.match-with{
  
}
.match-date{
  font-size: 13px;
}
.match-broadcast{
  font-size: 11px;
}

.match-score{
  
  font-size:13px;
}


  
  /* 양 옆의 슬라이드 */
  .swiper-slide-next,
  .swiper-slide-prev {
    transform: scale(0.75)!important;
    margin-top: 0 !important;
    color: black !important;
  }
  
  /* 화살표 버튼의 기본 스타일 */
  .swiper-button-next, .swiper-button-prev {
    width: 20px;
    height: 20px;
    background-color: rgba(200, 200, 200, 0.7);
    border-radius: 60%;
    color: black;
    fill: black;
  }

  
  
  /* 화살표 아이콘의 위치 조정 */
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 10px;
    font-weight: 900;
    position: relative;
    top: 0;
    left: 0;
  }
  

  
  /* 화살표 버튼 호버 시 스타일 */
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color: rgba(150, 150, 150, 0.9);
  }

  .thumbnail-image {
    width: 135px;  /* 원하는 너비 */
    height: auto;  /* 높이를 auto로 설정하면 너비에 맞춰 자동 조절됩니다 */
  }


/*  
  .mobile-swiper {width : 220px !important;}
  .swiper-wrapper {width : 220px !important;}
  .mobile-swiper-wrapper {width : 220px !important;}
  */
/* 모바일 932 이하*/
@media screen and (max-width: 932px) {
  
  .mobile-swiper {width: 285px !important;}

  .mobile-left-panel, .mobile-center-panel, .mobile-right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-left-header, .mobile-center-header, .mobile-right-header{
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 15px;

  }

  .mobile-left-panel table th, .mobile-left-panel table td {
    padding-left: 5px;
    padding-right: 5px;
  }

  

  /*
  .swiper-wrapper {width : 222px !important;}
  .mobile-swiper-wrapper {width : 223px !important;}
*/


  .player-section {
    padding-left: 2px; 
    align-items: flex-start; 
  }

  .player-image {
    width: 80px !important; /* 이미지 크기 줄이기 */
    height: auto; /* 비율 유지 */
  }
  .player-name {
    align-self: flex-start; /* 이름을 왼쪽으로 정렬 */
    width: 100%; /* 이름의 너비를 부모 요소의 너비로 설정 */
  }
}
