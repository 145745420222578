.calendar {
    display: flex;
    justify-content: space-between;
  }
  
.calendar-day {
    flex: 1;
    text-align: center;
    border: 1px solid #ccc; /* 테두리 적용 */
    border-radius: 10px; /* 모서리 둥글게 */

}

.today-label {
    display: block;
    font-size: 10px;
    font-weight: bold;
    height: 8px; /* 동일한 높이를 확보합니다. */
    line-height: 14px; /* 텍스트를 중앙에 정렬하기 위해 추가합니다. */
    
}

.calendar-day.today {
  
  background-color: rgba(255, 204, 0, 0.2); /* 배경색은 옅은 노란색으로 하이라이트 */
}
 
  .day-name {
    font-weight: bold;
    font-size: 12px;
  }
  
  .player-names {
    padding: 5px;
    margin-bottom: 2px;
    font-size: 12px;
    color: #555;
    white-space: pre-line;
  }


/* 스마트폰 */
@media screen and (max-width: 767px) {
    .calendar {
        /* flex-direction: column; 제거 */
        justify-content: space-around; /* 요소 간 공간 조정 */
    }
  
    .calendar-day {
        /* width: 90%; 제거 */
        flex: 1; /* 기존처럼 flex 설정 유지 */
        margin: 5px; /* 마진을 추가하여 요소 사이 간격 조정 */
        font-size: 12px;
    }

    .day-name, .player-names {
        font-size: 8px; /* 글자 크기 조정 */
    }

    .today-label {
        font-size: 7px;
    }
}


/* 패드 */
@media screen and (max-width: 1366px) {
  .calendar {
      /* flex-direction: column; 제거 */
      justify-content: space-around;
  }

  .calendar-day {
      /* width: 20%; 제거 */
      flex: 1;
      margin: 5px;
  }
}