.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    
    transition: height 0.3s ease-in-out;  /* height 속성에 대한 전환 효과를 추가합니다. */
    height: 350px;  /* 초기 높이를 설정합니다. */

    margin-bottom: 21px;
    max-height: 350px;  /* 최대 높이를 설정합니다. */
    overflow: hidden;  /* 내용이 넘칠 때 스크롤바를 생성하지 않습니다. */
    z-index: 1000; /* 이 값은 푸터보다 높아야 합니다. */

    

}

.chat-header {
    display: flex;
    justify-content: center; /* 중앙 정렬로 변경 */
    align-items: center;
    padding: 14px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    position: relative; /* 상대적 위치 지정 */
    cursor: pointer;
}

    .chat-toggle-icon {
        position: absolute; /* 절대 위치 지정 */
        right: 10px; /* 우측에서 10px 떨어진 곳에 위치 */
        color: #333;
    }
  
    .chat-header:hover .chat-toggle-icon {
    color: #FFD700; /* 아이콘 하이라이트 색상 */
    }

.chat-body {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;  /* 내용이 넘칠 때 세로 스크롤바를 생성합니다. */
    transition: max-height 0.3s ease-in-out;  /* max-height 속성에 대한 전환 효과를 추가합니다. */
    text-align: left;
}
.nickname {
    font-weight: bold;
}

.nickname-mine {
    font-weight: bold;
    color: rgb(160, 138, 16);
}

.nickname-others {
    font-weight: bold;
}


.chat-input-form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    
}

.chat-input-form input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;

}

.chat-input-form button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    
    background-color: #FFD700;  /* 노란색 */
    color: #000;  /* 글자색을 검은색으로 변경 */

    cursor: pointer;
}

.chat-input-form button:hover {
    background-color: #ffa600;  /* 더 진한 노란색 */
}

.chat-container.folded .chat-body,
.chat-container.folded .chat-input-form {
    max-height: 0;  /* 접힌 상태에서는 max-height를 0으로 설정합니다. */
}

.chat-container.folded {
    height: 45px;  /* 접힌 상태에서의 높이를 설정합니다. */
}

