.footer {
    background-color: white;
    color: #000000;
    padding: 8px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 13px;

      z-index: 1000; /* 임의의 높은 값 */

  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-text {
    margin: 0;
  }
  
  .social-icons {
    padding-left: 10px;
    display: flex;
  }
  
  .social-icon {
    font-size: 20px;
    color: #1d1d1d;
    margin-left: 10px;
  }
  
  .social-icon:first-child {
    margin-left: 0;
    color: rgb(255, 73, 73);
  }
  
  .social-icon:last-child {
    margin-left: 5px;
    color: blueviolet;
    font-size: 20px;
  }