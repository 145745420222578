body {
    background-color: #f5f5f5;
  }
  
  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: white;
    padding: 1rem;
    color: white;
  }
  
  .logo-container {
    /* div에 대한 스타일 */
  }
  
  .logo-image {
    width: 230px;
    height: auto;
    
  }
  .App-main {
    padding: 4rem;
    overflow-y: auto; /* 추가 */
  }
  


  .main-container {
    display: grid;
    grid-template-columns: 130px 1fr 1fr 130px;
    grid-template-rows: 50px auto 1fr;
    grid-template-areas:
      "a1 head head a2"
      "a1 main main a2"
      "a1 main main a2"
      "a1 main main a2"
      ".   a3 a3   .";
    height: calc(100vh - 10%);
    gap: 20px;
    background-color: transparent;
    
  }
  
  
  .head {
    grid-area: head;
    align-self: center; /* 세로 중앙 정렬 */
    height: auto; /* 높이를 자동으로 설정 */
    display: flex; /* flexbox 사용 */
    justify-content: center; /* 내용을 가로 중앙에 위치 */
    align-items: center; /* 내용을 세로 중앙에 위치 */

    margin-bottom: 10px;

  }
  
  .calendar {
    display: flex;
    justify-content: space-between;
    flex-direction: row; 
    width: 40%;
    
  }
  

  .main-content {
    padding: 1rem;
  }
  
  .a-panel {
    grid-area: a1;
    max-width: 120px;
    max-height: 600px;
    
  }
  
  .a-panel2 {
    grid-area: a2;
    max-width: 120px;
    max-height: 600px;
    
  }

  .a-panel img, .a-panel2 img {
    max-width: 100%;
    height: auto;
}
  
  .horizontal-a-panel {
    grid-area: a3; 
    /* grid-column: 1 / 4;*/
    /* grid-row: 2; */

    max-height: 100%;
    /* border: 1px solid gray; */
    /* background-color: #f2f2f2; */
  }
  
  .horizontal-a-panel img {
    max-width: 100%; /* 최대 너비 설정 */
    height: auto; /* 높이 자동 조절 */
    padding-bottom: 80px; /* Footer의 높이에 따라 조정 */
}

  .main-content {
    grid-area: main;
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 75px; /* Footer의 높이에 따라 조정 */
  }
  

  /* 태블릿 화면  */

  @media screen and (max-width: 1366px) {
    .a-panel, .a-panel2, .horizontal-a-panel{
      display: none !important; /* 태블릿 화면에서 광고 패널 숨기기 */
    }

    .head .calendar{
      width:70%;
      
    }
  
    .main-container {
      grid-template-columns: 1fr;
      grid-template-rows: 50px auto 1fr;
      grid-template-areas:
        "head"
        "main"
        "a3";
    }
  
    .main-content {
      margin-left: 20px; /* 좌우 여백 조정 */
      margin-right: 20px;
    }
  
    .calendar {
      width: calc(100% - 40px); /* 좌우 여백에 맞게 너비 조정 */
      margin: 0 20px;
    }
  }

  /* 스마트폰 화면 */
  @media screen and (max-width: 932px) {

    .App-main {
      padding: 1.5rem;
      overflow-y: auto;
    }

    .a-panel, .a-panel2, .horizontal-a-panel {
      display: none !important; /* 스마트폰 화면에서 광고 패널 숨기기 */
    }

  
    .main-container {
      grid-template-columns: 1fr;
      grid-template-rows: 50px auto 1fr;
      grid-template-areas:
        "head"
        "main"
        "a3";
      max-width: 100%;
    }
    .main-content {
      margin-left: 0px; /* 좌우 여백 조정 */
      margin-right: 0px;
      max-width: 100%; /* 가로 길이를 100%로 설정 */
    }
  
  
    .head .calendar {
      width: 100%; /* 좌우 여백에 맞게 너비 조정 */
      margin: 0 10px;
    }
  }

  @media screen and (max-width: 667px) {

    .App-main {
      padding: 1.5rem;
      overflow-y: auto;
    }

    .head .calendar .calendar-day {
      width: 100%; /* 좌우 여백에 맞게 너비 조정 */
      margin: 0px;

    }

    .a-panel, .a-panel2, .horizontal-a-panel {
      display: none !important; /* 스마트폰 화면에서 광고 패널 숨기기 */
    }

  
    .main-container {
      grid-template-columns: 1fr;
      grid-template-rows: 50px auto 1fr;
      grid-template-areas:
        "head"
        "main"
        "a3";
      max-width: 100%;
    }
    .main-content {
      margin-left: 0px; /* 좌우 여백 조정 */
      margin-right: 0px;
      max-width: 100%; /* 가로 길이를 100%로 설정 */
    }
  
  

  }